<template>
  <div>
    <Table stripe :data="list" :columns="tableColumns"  @on-selection-change="selectionChange"></Table>
    <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span>
      </i-col>
      <i-col  span='12'>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="handlePageChanged"
          ></Page>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getbillpage, deletebill, reeditbill } from '@/api/statement/bill'
import { formatsettlementStatus } from '@/utils/tagStatus'

export default {
  data () {
    return {
      statementApply: this.$store.getters.token.publisherSetting.statementApply,
      total: 0,
      query: {
        billType: 1,
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        agentCompanyId: null,
        startDate: '',
        endDate: ''
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          key: 'id',
          width: 60,
          align: 'center'
        },
        {
          title: '结算单编号',
          key: 'code'
        },
        {
          title: '结算单名称',
          key: 'name',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.name || '-'
              )
            ])
          }
        },
        {
          title: '结算公司',
          key: 'agentCompanyName'
        }, {
          title: '计费项金额',
          key: 'normalAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.normalAmount)
              )
            ])
          }
        },
        {
          title: '调整项金额',
          key: 'adjustAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.adjustAmount)
              )
            ])
          }
        },
        {
          title: '罚款项金额',
          key: 'penaltyAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.penaltyAmount)
              )
            ])
          }
        },
        {
          title: '最终结算金额',
          key: 'billAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.billAmount)
              )
            ])
          }
        },

        {
          title: '创建时间',
          key: 'createTime'
          // render: (h, params) => {
          //   return h('div', [h('span', '2020-06-13 12:54:47')])
          // }
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatsettlementStatus(
              h,
              data.row.status,
              data.row.statusName
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            const that = this
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$store.commit('set_settlement_pageType', 'Detail')
                      this.$store.commit('set_settlement_billId', params.row.id)
                    }
                  }
                },
                '详情'
              ),
              params.row.status === 6 && that.isAuth('settlement_transaction_create') && that.statementApply ? h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      that.$Modal.confirm({
                        title: '操作确认',
                        content: '<p>是否要重新编辑此结算单,重新编辑会回滚结算单状态为草稿，是否继续？</p>',
                        loading: true,
                        onOk: () => {
                          reeditbill({
                            billId: params.row.id
                          }).then((res) => {
                            that.$Modal.remove()
                            that.$store.commit('set_settlement_update', new Date())
                            that.$store.commit('set_settlement_pageType', 'Detail')
                            that.$store.commit('set_settlement_billId', params.row.id)
                          }).catch(() => {
                            that.$Modal.remove()
                          })
                        }
                      })
                    }
                  }
                },
                '编辑'
              ) : null,
              params.row.status === 0 && that.isAuth('settlement_transaction_create') ? h(
                'a',
                {
                  style: { marginRight: '5px', color: '#ef4f4f' },
                  on: {
                    click: () => {
                      that.$Modal.confirm({
                        title: '操作确认',
                        content: '<p>是否要确认删除此结算单？</p>',
                        loading: true,
                        onOk: () => {
                          deletebill({
                            billId: params.row.id
                          }).then((res) => {
                            if (res && !res.errcode) {
                              that.$Notice.success({ desc: '删除成功' })
                              if (that.feeitemIds.includes(params.row.id)) {
                                that.feeitemIds.splice(that.feeitemIds.indexOf(params.row.id), 1)
                                that.$store.commit('set_settlement_feeitemIds', that.feeitemIds)
                              }
                              that.$store.commit('set_settlement_update', new Date())
                            }
                            that.$Modal.remove()
                          }).catch(() => {
                            that.$Modal.remove()
                          })
                        }
                      })
                    }
                  }
                },
                '删除'
              ) : null

            ])
          }
        }
      ],

      currentPageAllItemIds: []
    }
  },
  created () {
    this.$store.commit('set_settlement_feeitemIds', null)
  },
  computed: {
    transactionSettlementQuery () {
      return this.$store.state.settlement.transactionSettlementQuery
    },
    feeitemIds () {
      return this.$store.state.settlement.feeitemIds || []
    },
    beginUpdate () {
      return this.$store.state.settlement.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.query.agentCompanyId = this.transactionSettlementQuery.agentCompanyId
      this.query.startDate = this.transactionSettlementQuery.startDate
      this.query.endDate = this.transactionSettlementQuery.endDate
      this.query.keyword = this.transactionSettlementQuery.keyword
      this.query.billStatus = this.transactionSettlementQuery.billStatus === -1 ? null : this.transactionSettlementQuery.billStatus
      this.reloadPage()
    }

  },
  methods: {
    reloadPage () {
      this.query.pageNumber = 1
      this.loadPage()
    },
    loadPage () {
      getbillpage(this.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = this.feeitemIds.includes(item.id)
            return item
          })
          this.currentPageAllItemIds = res.list.map(item => {
            return item.id
          })
        }

        this.list = res.list
        this.total = res.totalRow
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
          }
        }
      })
      this.$store.commit('set_settlement_feeitemIds', that.feeitemIds)
    }

  }
}
</script>
